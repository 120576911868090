import React, { useState, useEffect } from "react";
import { Navbar, Nav, Button, Dropdown } from "react-bootstrap";
import '../global.css'

const MainScreenMobile = () => {
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMenu(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const handleLanguageChange = (lang) => {
        // Funcție pentru a redirecționa utilizatorul către versiunea în limba aleasă
        if (lang === 'ru') {
            window.location.href = '/';
        } else if (lang === 'ro') {
            window.location.href = '/ro';
        }
    };

    return (
        <div id="landing" className="relative min-h-screen bg-gray-100 text-white font-open-sans">
            <img
                className="absolute mx-auto w-[215%] h-full "
                alt=""
                src="/background-1@2x.png"
            />
            <div className="navbar-container">
                <Navbar className={`w-full justify-content-center ${showMenu ? 'show-menu' : 'hide-menu'}`} expand="lg" variant="dark">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto tracking-[0.1em] uppercase whitespace-pre-wrap">
                            <Nav.Link className="mx-auto uppercase" href="#landing">Главная</Nav.Link>
                            <Nav.Link className="mx-auto uppercase" href="#about">Об игре</Nav.Link>
                            <Nav.Link className="mx-auto uppercase" href="#features">Особенности</Nav.Link>
                            <Nav.Link className="mx-auto uppercase" href="#characters">Кошмары</Nav.Link>
                            <Nav.Link className="mx-auto uppercase" href="#times">График Работы</Nav.Link>
                            <Nav.Link className="mx-auto uppercase" href="#reviews">Отзывы</Nav.Link>
                            <Nav.Link className="mx-auto uppercase" href="#footer">Бронирование</Nav.Link>
                            <Dropdown className="ml-3">
                                <Dropdown.Toggle variant="light" id="language-switcher">
                                    Язык
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleLanguageChange('ru')}>Русский</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleLanguageChange('ro')}>Română</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <h2 className="booking-text">
                    <a href="tel:078180301">
                        Бронируйте сейчас 078180301
                    </a>
                </h2>
            </div>

            <div className="absolute top-2/4 text-center w-full">
                <h1 className="text-4xl md:text-6xl font-rubik-wet-paint">Выжить Любой ценой</h1>
                <p className="text-lg md:text-xl font-bellota mt-4">Отчаянная охота: выживите ли вы в смертельной игре?</p>
                <Button onClick={() => window.location.href = '#footer'}
                    className="mt-8 w-[90%] md:w-[70%] mx-auto"
                    name="Register"
                    variant="warning"
                >
                    Связаться
                </Button>
            </div>

            <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
                <button onClick={() => window.location.href = '#about'} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                    <img
                        className="w-8 h-8"
                        alt=""
                        src="/scroll-down.svg"
                    />
                </button>
            </div>
        </div>
    );
};

export default MainScreenMobile;
