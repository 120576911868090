import React from "react";
import Accordion from "./Accordion";
import '../global.css'

const FeaturesRo = ({ AccordionList }) => {
    return (
        <div id="features" className="relative min-h-screen h-full md:h-[1300px] overflow-hidden bg-gray-100">
            <img
                className="absolute imagebg1 min-h-full inset-0 w-full h-full"
                alt=""
                src="/group@2x.png"
            />
            <div className="flex flex-col gap-y-4 items-end mr-10">
                <h1 className="relative text-xl mt-10 tracking-[2px] mr-7 uppercase font-alice text-white inline-block">
                    Ce este unic?
                </h1>
                <h1 className="relative specialties text-43xl tracking-[3px] mr-7 uppercase font-literata text-white inline-block">
                    Caracteristici
                </h1>
                <div className="w-full md:w-[70%] text-left relative">
                    <Accordion AccordionList={AccordionList} />
                </div>
            </div>
        </div>
    );
};

export default FeaturesRo;
