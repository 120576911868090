import React from 'react'
import '../global.css'

const FAQRo = () => {
    return (
        <div id='times'>
            <div className="time-table-container relative mt-[150px] ml-[40px] mr-[40px]">
                <h1 className='sm:text-47xl text-[40px] text-white font-bellota'>Ce trebuie să știi despre noi?</h1>
                <table className="time-table">
                    <thead>
                        <tr>
                            <th>Ora</th>
                            <th>Luni-Joi</th>
                            <th>Vineri</th>
                            <th>Sâmbătă-Duminică</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>13:00</td>
                            <td>900</td>
                            <td>900</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>14:30</td>
                            <td>900</td>
                            <td>900</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>16:00</td>
                            <td>950</td>
                            <td>1000</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>17:30</td>
                            <td>950</td>
                            <td>1000</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>19:00</td>
                            <td>1000</td>
                            <td>1000</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>20:30</td>
                            <td>1000</td>
                            <td>1000</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>22:00</td>
                            <td>1050</td>
                            <td>1050</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>23:30</td>
                            <td>1050</td>
                            <td>1050</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>00:45</td>
                            <td>1100</td>
                            <td>1100</td>
                            <td>1100</td>
                        </tr>
                        <tr>
                            <td>02:00</td>
                            <td>1100</td>
                            <td>1100</td>
                            <td>1100</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="extreme-mode-container relative mt-[50px] ml-[40px] mr-[40px]">
                <h1 className="extreme-mode-title text-[36px] text-white font-needleteeth-sp">Modul extrem</h1>
                <p className="extreme-mode-description text-white text-lg mt-[20px]">
                    Doar la noi aveți posibilitatea să alegeți <a className='no-underline text-rose-600' href='#footer'>MODUL EXTREM DE JOC !!!</a>
                    <br />PLUS 20 DE MINUTE PENTRU CĂUTAREA ȘI REPARAREA GENERATOARELOR !!!
                    <br />Nivel maxim de frică, contact fizic complet cu utilizarea inventarului, sarcini și diviziuni individuale.
                </p>
            </div>
        </div>
    )
}

export default FAQRo;
