import Newsletter from "../components/Newsletter";
import Characters from "../components/Characters";
import Features from "../components/Features";
import AboutGallery from "../components/AboutGallery";
import MainScreen from "../components/MainScreen";
import FAQ from "../components/Faq";
import Reviews from "../components/Reviews";

const Desktop = () => {
  const AccordionList = [
    {
      q: "Простор и Выбор актеров.",
      a: "Большая территория: У вас в распоряжении более 300+кв.м, чтобы исследовать разгадывать загадки и прятаться от маньяка. Выбирайте из множества актеров, чтобы добавить уникальность и индивидуальность в ваше приключение."
    },
    {
      q: "Уникальные технологии и инновации.",
      a: "Насладитесь загадками которые будут вызывать волнение и требовать вашей командной работы, а также современные технологии, которые погрузят вас еще глубже в атмосферу кошмара..."
    },
    {
      q: "Фиксированное время прохождения.",
      a: "Вам доступно ровно 60 минут, чтобы пройти весь квест.Не больше, не меньше.Это создает непрерывный драйв и напряжение, оставляя вас в ожидании каждой секунды, вперемешку с адреналином и страхом, на протяжении всей игры.Однако, если вы выберете экстремальный уровень, ваше прохождение увеличится до 80 минут, добавляя еще больше ужаса и испытаний для вашей команды."
    },
    {
      q: "Фото и видеофиксация прохождения квеста.",
      a: "Узнайте, как каждый момент вашего приключения останется в памяти навсегда.Отдельная фотозона с кошмарами из квеста - создайте незабываемые воспоминания, запечатлив свои встречи с ужасными существами, которые ожидают вас в темноте."
    }
  ];

  return (
    <div className="w-full relative h-full ">
      <img
        className="absolute w-full h-full object-cover"
        alt=""
        src="/bg-image-5@2x.png"
      />
      <MainScreen />
      <AboutGallery />
      <Features AccordionList={AccordionList} />
      <Characters />
      <FAQ />
      <Reviews />
      <Newsletter />

    </div>
  );
};

export default Desktop;
