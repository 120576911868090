
import AboutGalleryRo from "../components/AboutGalleryRo";
import CharactersRo from "../components/CharactersRo";
import FAQRo from "../components/FaqRo";
import FeaturesRo from "../components/FeaturesRo";
import MainScreenRo from "../components/MainScreenRo";
import NewsletterRo from "../components/NewsletterRo";
import Reviews from "../components/Reviews";

const DesktopRo = () => {
    const AccordionList = [
        {
            q: "Spațiu amplu și alegerea actorilor.",
            a: "Teritoriu mare: Aveți la dispoziție peste 300 mp pentru a explora, a rezolva enigme și a vă ascunde de maniac. Alegeți dintre numeroși actori pentru a adăuga unicitate și individualitate aventurii dvs."
        },
        {
            q: "Tehnologii și inovații unice.",
            a: "Bucurați-vă de enigme care vor stârni emoție și vor necesita munca în echipă, precum și de tehnologii moderne care vă vor cufunda și mai adânc în atmosfera coșmarului..."
        },
        {
            q: "Timp fixat pentru completarea jocului.",
            a: "Aveți exact 60 de minute pentru a parcurge întregul quest. Nu mai mult, nu mai puțin. Aceasta creează un flux continuu de adrenalină și tensiune, lăsându-vă să așteptați fiecare secundă, într-un amestec de adrenalină și frică, pe tot parcursul jocului. Cu toate acestea, dacă alegeți nivelul extrem, timpul dvs. de joc se va mări la 80 de minute, adăugând și mai multă groază și provocări pentru echipa dvs."
        },
        {
            q: "Fotografiere și înregistrare video a parcursului questului.",
            a: "Aflați cum fiecare moment al aventurii dvs. va rămâne pentru totdeauna în memorie. O zonă foto separată cu coșmarurile din quest - creați amintiri de neuitat, capturând întâlnirile dvs. cu creaturile înfiorătoare care vă așteaptă în întuneric."
        }
    ];
    return (
        <div className="w-full relative h-full ">
            <img
                className="absolute w-full h-full object-cover"
                alt=""
                src="/bg-image-5@2x.png"
            />
            <MainScreenRo />
            <AboutGalleryRo />
            <FeaturesRo AccordionList={AccordionList} />
            <CharactersRo />
            <FAQRo />
            <Reviews />
            <NewsletterRo />

        </div>
    );
};

export default DesktopRo;
