import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Card } from "react-bootstrap";
import '../global.css'

const CharactersRo = () => {
    const [selectedNightmareIndex, setSelectedNightmareIndex] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const nightmares = [
        {
            name: "The Jason",
            description:
                "Jason Voorhees - un maniac înviat din adâncurile întunecate ale Crystal Lake, pentru a-și urmări și extermina victimele. Forța și nemilosul său sunt greu de descris în cuvinte - va trebui să vezi cu ochii tăi când începe vânătoarea.",
            imageSrc: "/jason.png",
        },
        {
            name: "The Nightmare",
            description:
                "Freddy Krueger - un maniac care își urmărește victimele în propriile lor vise. Lamele lui creează coșmaruri din care nu există scăpare.",
            imageSrc: "/freddy.png",
        },
        {
            name: "The Trapper",
            description:
                "The Trapper creează capcane pentru a-și prinde victimele. Plăcerea sa pervertită constă în a urmări cum victimele sale cad în dispozitivele sale mortale.",
            imageSrc: "/trapper.png",
        },
        {
            name: "The Cannibal",
            description:
                "The Cannibal preferă să folosească uneltele sale preferate - ferăstrăul și cuțitul. Nu doar că își vânează victimele, dar își împrăștie uneltele teribile pentru a le speria și prinde.",
            imageSrc: "/cannibal.png",
        },
        {
            name: "Vârcolacul",
            description:
                "Vârcolacul este un coșmar care își schimbă forma în timpul lunii pline, lăsând în urmă urme de distrugere și vărsare de sânge. Pregătește-te să lupți cu un monstru care se ascunde în umbrele pădurii și te vânează când noaptea cade.",
            imageSrc: "/unknown.png",
        },
        {
            name: "The Chucky",
            description:
                "Chucky este o icoană a groazei, reînviată din trecut pentru a te face să te temi din nou. Va trebui să lupți pentru viața ta când Chucky te va urmări în această lume a coșmarurilor.",
            imageSrc: "/chucky.png",
        },
        {
            name: "Scream",
            description:
                "Scream este un maniac care aduce cu el un țipăt mortal, umplând aerul cu groază și teamă. Atacurile sale sunt străpungătoare și imprevizibile. Pregătește-te să întâlnești un monstru care te așteaptă în cele mai întunecate colțuri și se pregătește să dea lovitura mortală când vine momentul.",
            imageSrc: "/krik.png",
        },
        {
            name: "The Clown",
            description:
                "Clovnul este un maniac al cărui teroare se ascunde sub masca bucuriei și fericirii. Glumele sale sângeroase și trucurile macabre transformă fiecare spectacol într-un coșmar din care nu poți scăpa. Pregătește-te să întâlnești groaza care se ascunde în spatele unui zâmbet strălucitor și a numeroase baloane.",
            imageSrc: "/clown.png",
        }
    ];

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleCardClick = (index) => {
        setSelectedNightmareIndex(index);
    };

    return (
        <div id="characters" className="relative w-full h-full text-white">
            <h1 className="sm:text-47xl text-[50px] tracking-[0.04em] leading-[116.6%] text-center uppercase font-literata ">
                Dușmanii din întuneric: <br />Alege-ți coșmarul.
            </h1>
            <div className="text-center">
                <p className="text-xl font-pangolin mx-auto inline-block my-4">
                    În această secțiune trebuie să-ți alegi urmăritorul din lista de maniaci înfricoșători.
                    <br />
                    Fă-ți alegerea și pregătește-te pentru un coșmar care nu te va lăsa în pace nici măcar o secundă.
                </p>
            </div>
            <div className="flex justify-center mt-[60px] flex-wrap">
                {nightmares.map((nightmare, index) => (
                    <div
                        key={index}
                        className={`flex-shrink-0 px-auto nightmare-card ${index === selectedNightmareIndex ? "focused" : ""}`}
                        style={{ marginBottom: "150px" }}
                        onClick={() => handleCardClick(index + startIndex)}
                    >
                        <Card style={{ width: '250px', height: '360px' }} className="shadow-sm poscards">
                            <Card.Img variant="top" src={nightmare.imageSrc} />
                            <Card.Body>
                                <Card.Text className="text-45xl text-center text-white font-ghastly-panic-cyr">{nightmare.name}</Card.Text>
                                <Card.Text className="text-5xl leading-[22px] font-needleteeth-sp text-tomato">{nightmare.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>

            <div className="twins-nightmare mt-[-450px]">
                <img
                    className="absolute bgrectancgle rounded-3xs left-[20%] w-[70%] h-[500px] bottom-8"
                    alt=""
                    src="/rectangle-5@2x.png"
                />
                <div className="relative left-[20%] w-[70%] top-8 mt-[520px] max-w-[65%]">
                    <div className="flex flex-col">
                        <div className="leading-[116.6%] nightmare-header ml-[600px] text-29xl font-literata uppercase items-center">
                            Coșmar dublu: întâlnește teroarea în dublu exemplar
                        </div>
                        <p className="text-base nightmare-description tracking-[0.04em] ml-[600px] mt-8 leading-[185.1%] font-lato">
                            Modul sinistru "Gemenii" oferă o alegere unică: doi coșmaruri care te vor urmări pe tine și echipa ta. Poți alege singur ce groază vei trăi sau poți lăsa soarta să aleagă pentru tine. Acești doi maniaci, acționând împreună, creează atacuri imprevizibile și o atmosferă specială de teamă, făcând fiecare joc unic. Preț +150 lei.
                        </p>
                        <Button
                            className="relative ml-[600px] buttonn w-[300px] h-[63.8px]"
                            alt=""
                            src="/rectangle-1.svg"
                            name="playgame"
                            variant="outline-primary"
                            onClick={() => window.location.href = "#footer"}
                        >
                            Joacă
                        </Button>
                    </div>
                    <img
                        className="relative nightmare-photo mt-[-550px] bottom-16 max-w-[560px] h-[687px]"
                        alt=""
                        src="/123.png"
                    />
                </div>
            </div>
        </div>
    );
};

export default CharactersRo;
