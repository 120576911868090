import React from 'react';

const FooterRo = () => {
    return (
        <div className="footer-container relative text-white py-5">
            <div className="location-info text-center p-4">
                <h2 className="text-2xl font-bebas-neue text-yellow-700 mb-2">Locația noastră</h2>
                <p className="text-lg mb-4">Chișinău, Mesager 24</p>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2718.7883348540927!2d28.813936700000003!3d47.044384099999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d2493b1cc3f%3A0xf05578c6e4d02bbf!2zREJEIOKAlCDQnNC10YDRgtCy0Ysg0Log0KDQsNGB0YHQstC10YLRgw!5e0!3m2!1sru!2s!4v1717686442750!5m2!1sru!2s"
                    height="200"
                    className='w-full'
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <div className="hours mt-4">
                    <h3 className="text-xl font-semibold">Program de lucru</h3>
                    <p className="text-lg">Luni - Duminică: 08:30 AM - 02:00 AM</p>
                    <p className='text-[15px] font-literata'>Stimați oaspeți! <br />
                        Vă rugăm să confirmați rezervările dvs. prin telefoanele de contact după ce ați făcut rezervarea pe site. <br />
                        P.S. Este interzis să veniți în tocuri și rochii!!! <br />
                        Mulțumim anticipat!</p>
                </div>
            </div>
            <nav className="mt-5">
                <div className="flex flex-wrap justify-center gap-x-10 gap-y-7 text-lg font-lato">
                    <a href="#landing" className="footers">Acasă</a>
                    <a href="#about" className="footers">Despre joc</a>
                    <a href="#features" className="footers">Caracteristici</a>
                    <a href="#characters" className="footers">Coșmaruri</a>
                    <a href="#times" className="footers">Orar</a>
                    <a href="#reviews" className="footers">Recenzii</a>
                    <a href="https://www.instagram.com/dbdmd13?igsh=MWMwaWd2ajUyYzJ6OQ==">
                        <img
                            className="w-[25px] h-[25px] relative"
                            src="/inst.svg" alt="Instagramul nostru" />
                    </a>
                    <a href="https://peach-it.solutions/" target="_blank">
                        <img
                            className="w-full h-[20px] relative"
                            src="/Made by Peach IT orange.png" alt="Realizat de Peach IT" />
                    </a>
                </div>
            </nav>
        </div>
    );
};

export default FooterRo;
