import React from "react";
import { Navbar, Nav, Button, Dropdown } from "react-bootstrap";
import '../global.css'

const MainScreen = () => {
  const handleLanguageChange = (lang) => {
    // Funcție pentru a redirecționa utilizatorul către versiunea în limba aleasă
    if (lang === 'ru') {
      window.location.href = '/';
    } else if (lang === 'ro') {
      window.location.href = '/ro';
    }
  };

  return (
    <div id="landing" className="relative min-h-screen bg-gray-100 text-right text-xs text-white font-open-sans">
      <img
        className="absolute inset-0 w-full h-full object-cover"
        alt=""
        src="/background-1@2x.png"
      />
      <Navbar className="absolute w-full mx-auto" expand="lg" variant="dark">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="mr-auto tracking-[0.1em] uppercase  whitespace-pre-wrap">
            <Nav.Link className="mx-auto mr-12 uppercase" href="#landing">Главная</Nav.Link>
            <Nav.Link className="mx-auto mr-12 uppercase" href="#about">Об игре</Nav.Link>
            <Nav.Link className="mx-auto mr-12 uppercase" href="#features">Особенности</Nav.Link>
            <Nav.Link className="mx-auto mr-12 uppercase" href="#characters">Кошмары</Nav.Link>
            <Nav.Link className="mx-auto mr-12 uppercase" href="#times">График Работы</Nav.Link>
            <Nav.Link className="mx-auto mr-12 uppercase" href="#reviews">Отзывы</Nav.Link>
            <Nav.Link className="mx-auto mr-12 uppercase" href="#footer">Бронирование</Nav.Link>
          </Nav>
          <Dropdown className="ml-3">
            <Dropdown.Toggle variant="light" id="language-switcher">
              Язык
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleLanguageChange('ru')}>Русский</Dropdown.Item>
              <Dropdown.Item onClick={() => handleLanguageChange('ro')}>Română</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            href="tel:078180301"
            style={{
              backgroundColor: '#d9534f', /* Красноватый оттенок */
              border: 'none',
              color: 'white',
              fontFamily: 'Arial, sans-serif', /* Красивый шрифт */
              fontWeight: 'bold',
              padding: '10px 10px',
              textTransform: 'uppercase',
              borderRadius: '5px',
              transition: 'background-color 0.3s ease'
            }}
            className="ml-3 font-alice"
            onMouseEnter={(e) => e.target.style.backgroundColor = '#c9302c'}
            onMouseLeave={(e) => e.target.style.backgroundColor = '#d9534f'}
          >
            Бронируйте сейчас 078180301
          </Button>
        </Navbar.Collapse>
      </Navbar>


      <div className="absolute textmain w-[80.31%] top-[calc(50%_+_56.5px)]  left-[9.22%] text-61xl tracking-[3px] uppercase font-rubik-wet-paint text-center inline-block h-[73.1px]">
        Выжить Любой ценой
      </div>
      <div className="absolute textadd h-[3.08%] w-[54.51%] top-[67.42%] left-[23.33%] text-5xl tracking-[2px] font-bellota text-center inline-block">
        Отчаянная охота: выживите ли вы в смертельной игре?
      </div>
      <Button onClick={() => window.location.href = '#footer'}
        className="mx-auto w-[25%] md:w-[18%]  absolute h-[6.59%]  bottom-[15%] left-[38.64%]"
        name="Register"
        variant="warning"
      >
        Связаться
      </Button>
      <div className="absolute bottom-0 left-[48%] w-[58px] h-[48.2px] text-justify">
        <button onClick={() => window.location.href = '#about'} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
          <img
            className="absolute w-[20.3px] h-[8.2px]"
            alt=""
            src="/scroll-down.svg"
          />
        </button>

      </div>
    </div>
  );
};

export default MainScreen;
