import React from 'react';
import Slider from 'react-slick';
import './Reviews.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Reviews = () => {
    const reviews = [
        { id: 1, videoUrl: 'https://player.vimeo.com/video/1001910654', name: 'First Review' },
        { id: 2, videoUrl: 'https://player.vimeo.com/video/994925860', name: 'Second Review' },
        { id: 3, videoUrl: 'https://player.vimeo.com/video/1001910735', name: 'Third Review' },
    ];

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        pauseOnHover: true,
    };

    return (
        <div className="reviews-container">
            <h2 className="reviews-title relative font-alegreya">Reviews</h2>
            <Slider {...settings} className="video-gallery ">
                {reviews.map(review => (
                    <div key={review.id} className="video-item">
                        <iframe
                            src={review.videoUrl}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title={`Отзыв от ${review.name}`}
                        ></iframe>
                        <p className="reviewer-name">{review.name}</p>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Reviews;
