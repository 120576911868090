import { useState, useEffect, React } from "react";
import { Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isSameDay } from 'date-fns';
import '../global.css'
import Footer from "./Footer";
<script src="https://smtpjs.com/v3/smtp.js"></script>

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [participants, setParticipants] = useState(2); // Состояние для выбора количества участников
  const [level, setLevel] = useState("normal"); // Состояние для выбора уровня сложности
  const [selectedNightmare, setSelectedNightmare] = useState(""); // Состояние для выбранного кошмара
  const [selectedTwinNightmares, setSelectedTwinNightmares] = useState([]); // Состояние для выбранных кошмаров Близнецов
  const currentDate = new Date();
  const minBookingDateTime = new Date(currentDate.getTime() + 45 * 60000);

  useEffect(() => {
    if (participants >= 8) {
      setSelectedNightmare("Близнецы");
    } else if (selectedNightmare === "Близнецы") {
      setSelectedNightmare("");
      setSelectedTwinNightmares([]);
    }
  }, [participants]);

  const handleChange = (event) => {
    if (event.target.id === "email") {
      setEmail(event.target.value);
    } else if (event.target.id === "name") {
      setName(event.target.value);
    } else if (event.target.id === "phone") {
      setPhoneNumber(event.target.value);
    } else if (event.target.id === "participants") {
      setParticipants(parseInt(event.target.value));
    } else if (event.target.id === "level") {
      setLevel(event.target.value);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleNightmareChange = (event) => {
    setSelectedNightmare(event.target.value);
    setSelectedTwinNightmares([]);
  };

  const handleTwinNightmaresChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      if (selectedTwinNightmares.length < 2) {
        setSelectedTwinNightmares(prev => [...prev, value]);
      } else {
        event.target.checked = false;
      }
    } else {
      setSelectedTwinNightmares(prev => prev.filter(nightmare => nightmare !== value));
    }
  };


  const sendEmail = (event) => {
    event.preventDefault();

    // Проверяем, что имя и email не пустые
    if (!name.trim() || !email.trim() || !phoneNumber.trim()) {
      alert("Пожалуйста, заполните все обязательные поля.");
      return;
    }

    // Проверяем, что выбран хотя бы один кошмар
    if (!selectedNightmare) {
      alert("Пожалуйста, выберите хотя бы один кошмар.");
      return;
    }

    // Проверяем формат номера телефона
    const phoneRegex = /^\+373\d{8}$/;
    if (!phoneRegex.test(phoneNumber)) {
      alert("Пожалуйста, введите правильный номер телефона (начиная с +373 и состоящий из 11 цифр).");
      return;
    }

    // Проверяем, что выбраны два кошмара при выборе режима "Близнецы"
    if (selectedNightmare === "Близнецы" && selectedTwinNightmares.length !== 2) {
      alert("Пожалуйста, выберите ровно два кошмара для режима 'Близнецы'.");
      return;
    }

    if (!selectedDate || selectedDate < minBookingDateTime) {
      alert("Пожалуйста, выберите доступное время для бронирования (не менее чем за полтора часа от текущего времени).");
      return;
    }

    let selectedNightmaresText = selectedNightmare;
    if (selectedNightmare === "Близнецы") {
      selectedNightmaresText = selectedTwinNightmares.join(", ");
    }

    // Отправка электронного письма
    Email.send({
      Host: "smtp.elasticemail.com",
      Username: "claustrophobiamd@gmail.com",
      Password: "4DC98D26EDDE3E064BF11E9BB0CEE14499B1",
      To: "claustrophobiamd@gmail.com",
      From: "claustrophobiamd@gmail.com",
      Subject: "Запрос на бронирование квеста",
      Body: `Имя: ${name} <br/> Email: ${email} <br/> Номер телефона: ${phoneNumber} <br/> Количество участников: ${participants} <br/> Уровень сложности: ${level} <br/> Дата и время бронирования: ${selectedDate} <br/> Выбранные кошмары: ${selectedNightmaresText}`, // Включаем выбранные кошмары в тело письма
    }).then(() => {
      toast.success("Бронирование успешно отправлено!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }).catch(() => {
      toast.error("Произошла ошибка при отправке бронирования. Попробуйте еще раз.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  return (
    <div id="footer" className="relative w-full h-full footer pb-5 overflow-hidden text-left text-sm text-white font-open-sans">
      <div className="relative  min-h-full">
        <img className="absolute inset-0 w-full h-screen object-cover opacity-65" alt="" src="/bg5-1@2x.png" />
        <div className="relative top-[60px] pl-12 w-full text-left text-[20px] sm:text-17xl tracking-[2px] font-rubik-wet-paint inline-block">
          Бронирование квеста
        </div>
        <div className="relative top-[50px] pt-4 pl-12 w-full text-left text-[10px] sm:text-[15px] tracking-[3px] uppercase font-spectral">
          Цена за команду от 2 до 4 человек: 1000 лей<br />
          Дополнительный игрок +200лей<br />
          Возраст от 10+ и 18+<br />
          Экстремальный режим +200лей<br />
          Близнецы(второй актер на выбор) +150 лей<br />

        </div>
        <div className="relative top-[60px] pl-12 sm:w-[50%] w-[75%] text-left text-[13px] sm:text-lg leading-[22px]">
          Гарантируйте себе место в центре действия - забронируйте участие в квесте DBD . Наша команда профессионалов гарантирует вам незабываемые впечатления и захватывающий опыт. Не упустите шанс окунуться в мир приключений и адреналина- бронируйте сейчас!        </div>
        <form onSubmit={sendEmail} className="relative top-[100px] sm:left-[150px] w-9 left-5 sm:w-[18.08%]">
          <input
            id="email"
            type="email"
            placeholder="Ваш email адрес"
            className="sm:w-full bg-transparent border-b min-w-[220px] border-gray-400 text-white focus:outline-none focus:border-yellow-500"
            value={email}
            onChange={handleChange}
          />
          <input
            id="name"
            type="text"
            placeholder="Имя Фамилия"
            className="sm:w-full bg-transparent border-b min-w-[220px] border-gray-400 text-white focus:outline-none focus:border-yellow-500"
            value={name}
            onChange={handleChange}
          />
          <input
            id="phone"
            type="phone"
            placeholder="Номер телефона (+373xxxxxxxx)"
            className="sm:w-full bg-transparent border-b min-w-[220px] border-gray-400 text-white focus:outline-none focus:border-yellow-500"
            value={phoneNumber}
            onChange={handleChange}
          />
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            placeholderText="Выберите время"
            showTimeSelect
            timeInputLabel="Time:"
            dateFormat="dd/MM/yyyy HH:mm"
            minDate={new Date()} // Set minimum date to current date
            minTime={selectedDate && isSameDay(selectedDate, new Date()) ? minBookingDateTime : null} // Set minimum time only if selected date is today
            maxTime={selectedDate && isSameDay(selectedDate, new Date()) ? new Date(new Date().setHours(23, 59)) : null} // Set maximum time only if selected date is today
            className="sm:w-full bg-transparent border-b min-w-[220px] border-gray-400 text-white focus:outline-none focus:border-yellow-500"
          />
          <Form.Group controlId="participants" className="mt-2 w-[250px]">
            <Form.Label>Выберите количество участников:</Form.Label>
            <Form.Control as="select" onChange={handleChange} value={participants}>
              <option value={2}>2 человека</option>
              <option value={3}>3 человека</option>
              <option value={4}>4 человека</option>
              <option value={5}>5 человек</option>
              <option value={6}>6 человек</option>
              <option value={7}>7 человек</option>
              <option value={8}>8 человек</option>
              <option value={9}>9 человек</option>
              <option value={10}>10 человек</option>
              <option value={11}>11 человек</option>
              <option value={12}>12 человек</option>
              <option value={13}>13 человек</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="level" className="mt-2 w-[250px]">
            <Form.Label>Выберите уровень сложности:</Form.Label>
            <Form.Control as="select" onChange={handleChange} value={level}>
              <option value="normal">Обычный (60 минут)</option>
              <option value="extreme">Экстремальный(80 минут)</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="nightmares" className="mt-2 w-[250px]">
            <Form.Label>Выберите кошмары:</Form.Label>
            <Form.Control
              as="select"
              onChange={handleNightmareChange}
              value={selectedNightmare}
              disabled={participants >= 8}
            >
              <option value="">Выберите...</option>
              <option value="Jason">Jason</option>
              <option value="Freddy">Freddy</option>
              <option value="Trapper">Trapper</option>
              <option value="Cannibal">Cannibal</option>
              <option value="Оборотень">Оборотень</option>
              <option value="Chucky">Chucky</option>
              <option value="Scream">Крик</option>
              <option value="Clown">Clown</option>

              <option className="text-red font-pangolin" value="Близнецы">Близнецы (Два Актёра)</option>
            </Form.Control>
          </Form.Group>
          {selectedNightmare === "Близнецы" && (
            <Form.Group controlId="twinNightmares" className="relative mt-2">
              <Form.Label>Выберите двух кошмаров:</Form.Label>
              <div className="checkbox-container">
                {["Jason", "Freddy", "Trapper", "Крик", "Оборотень", "Chucky", "Cannibal", "Сюрприз"].map((nightmare, index) => (
                  <Form.Check
                    key={index}
                    type="checkbox"
                    label={nightmare}
                    value={nightmare}
                    onChange={handleTwinNightmaresChange}
                    checked={selectedTwinNightmares.includes(nightmare)}
                  />
                ))}
              </div>
            </Form.Group>
          )}
          <Button type="submit" className="sm:mt-2 h-[50px] mt-3 min-w-[250px] sm:w-full" variant="warning">
            Забронировать
          </Button>
        </form>
        <ToastContainer />

      </div>

      <Footer />
    </div>
  );
};

export default Newsletter;
