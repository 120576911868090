import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Card } from "react-bootstrap";
import '../global.css'

const Characters = () => {
  const [selectedNightmareIndex, setSelectedNightmareIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  const nightmares = [
    {
      name: "The Jason",
      description:
        "Джейсон Вурхиз - маньяк, воскресший из темных глубин Кристалл Лейк, чтобы преследовать и истреблять свои жертвы. Его силу и беспощадность сложно описать словами - вам придется увидеть это своими глазами, когда он начнет свою охоту. ",
      imageSrc: "/jason.png",
    },
    {
      name: "The Nightmare",
      description:
        "Фредди Крюгер - маньяк, который охотится на своих жертв в их собственных снах. Его лезвия создают кошмары, из которых нет спасения.",
      imageSrc: "/freddy.png",
    },
    {
      name: "The Trapper",
      description:
        "The Trapper создает ловушки, чтобы поймать своих жертв. Его извращенное удовольствие заключается в том, чтобы наблюдать, как его жертвы попадают в его смертельные устройства.",
      imageSrc: "/trapper.png",
    },
    {
      name: "The Cannibal",
      description:
        "The Cannibal предпочитает использовать свои любимые инструменты - пилу и нож. Он не только охотится на своих жертв, но и разбрасывает свои ужасные инструменты, чтобы запугать и поймать их.",
      imageSrc: "/cannibal.png",
    },
    {
      name: "Оборотень",
      description:
        "Оборотень - это кошмар, который меняет форму в полнолуние, оставляя за собой следы разрушения и кровопролития. Приготовьтесь к борьбе с монстром, который прячется в тени леса и охотится на вас, когда ночь наступит.",
      imageSrc: "/unknown.png",
    },
    {
      name: "The Chucky",
      description:
        "Чаки - это икона ужаса, воскрешенная из прошлого, чтобы снова заставить вас бояться. Вам предстоит бороться за свою жизнь, когда Чаки будет преследовать вас в этом кошмарном мире.",
      imageSrc: "/chucky.png",
    },
    {
      name: "Крик",
      description:
        " Крик - это маньяк, который приносит с собой смертельный вопль, наполняющий воздух ужасом и страхом. Его атаки пронзительны и непредсказуемы. Подготовьтесь к встрече с монстром, который выжидает вас в самых темных уголках и готовится нанести смертельный удар, когда момент придет.",
      imageSrc: "/krik.png",
    },
    {
      name: "The Clown",
      description:
        " Клоун - это маньяк, чей ужас скрывается за маской веселья и радости. Его кровавые шутки и макабрные трюки превращают каждое представление в кошмар, из которого не сбежать. Подготовьтесь к встрече с ужасом, который скрывается за яркой улыбкой и множеством шариков.",
      imageSrc: "/clown.png",
    }
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleCardClick = (index) => {
    setSelectedNightmareIndex(index);
  };


  return (
    <div id="characters" className="relative w-full h-full text-white">
      <h1 className="sm:text-47xl text-[50px] tracking-[0.04em] leading-[116.6%] text-center uppercase font-literata ">
        Враги во мраке: <br />Выбери свой кошмар.
      </h1>
      <div className="text-center">
        <p className="text-xl font-pangolin  mx-auto inline-block my-4 ">
          В этом разделе вам предстоит выбрать своего преследователя из списка ужасающих маньяков.
          <br />
          Сделайте свой выбор и готовьтесь к кошмару, который не оставит вас в покое ни на секунду.
        </p>
      </div>
      <div className="flex justify-center mt-[60px] flex-wrap">
        {nightmares.map((nightmare, index) => (
          <div
            key={index}
            className={`flex-shrink-0 px-auto nightmare-card ${index === selectedNightmareIndex ? "focused" : ""}`}
            style={{ marginBottom: "150px" }}
            onClick={() => handleCardClick(index + startIndex)}
          >
            <Card style={{ width: '250px', height: '360px' }} className="shadow-sm poscards">
              <Card.Img variant="top" src={nightmare.imageSrc} />
              <Card.Body>
                <Card.Text className="text-45xl text-center  text-white font-ghastly-panic-cyr">{nightmare.name}</Card.Text>
                <Card.Text className="text-5xl leading-[22px] font-needleteeth-sp text-tomato">{nightmare.description}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>

      <div className="twins-nightmare mt-[-450px]">
        <img
          className="absolute bgrectancgle rounded-3xs left-[20%] w-[70%] h-[500px] bottom-8 "
          alt=""
          src="/rectangle-5@2x.png"
        />
        <div className="relative left-[20%] w-[70%] top-8 mt-[520px] max-w-[65%]">
          <div className="flex flex-col " >
            <div className="leading-[116.6%]  nightmare-header ml-[600px] text-29xl  font-literata uppercase  items-center">
              Двойной кошмар: встречайте ужас в двойном размере
            </div>
            <p className="text-base nightmare-description tracking-[0.04em] ml-[600px]  mt-8 leading-[185.1%] font-lato">
              Зловещий режим "Близнецы" предлагает уникальный выбор: два кошмара, которые будут преследовать вас и вашу команду. Вы можете сами выбрать, какие ужасы вам предстоит пережить, или довериться судьбе и позволить игре выбрать за вас. Эти два маньяка, действуя вместе, создают непредсказуемые атаки и особенную атмосферу страха, делая каждое прохождение уникальным. Цена +150 лей.
            </p>
            <Button
              className="relative ml-[600px] buttonn w-[300px]  h-[63.8px] "
              alt=""
              src="/rectangle-1.svg"
              name="playgame"
              variant="outline-primary"
              onClick={() => window.location.href = "#footer"}

            >
              Играть
            </Button>

          </div>
          <img
            className="relative nightmare-photo mt-[-550px] bottom-16 max-w-[560px] h-[687px] "
            alt=""
            src="/123.png"
          />
        </div>
      </div>
    </div>
  );
};
export default Characters;