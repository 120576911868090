import React from "react";
import '../global.css'

const AboutGalleryMobileRo = () => {
    return (
        <div id="about" className="relative h-full min-h-full bg-gray-100 text-left text-lg text-white font-open-sans">
            <img
                className="absolute inset-0 w-full h-full object-cover"
                alt=""
                src="/bg4-1@2x.png"
            />
            <div className="relative flex flex-col justify-center items-center">
                <div className="text-center m-5 mb-0">
                    <div className="text-3xl font-bold tracking-wide font-cormorant uppercase">
                        Ce este DBD?
                    </div>
                    <p className="mt-4 font-light ">
                        În această aventură captivantă, vă veți găsi într-o lume nemiloasă a Dead by Daylight, jucând rolul unui supraviețuitor. Trebuie să vă perfecționați reflexele, să planificați strategic fiecare mișcare și să vă amintiți misiunea principală: să reparați 5 generatoare care vor fi cheia salvării voastre. Cufundați-vă în lumea coșmarurilor și testați-vă forțele în cea mai mortală vânătoare din viața voastră.
                    </p>
                </div>
                <div className="w-[50%] mt-0">
                    <img
                        className="w-full"
                        alt=""
                        src="/icon.svg"
                    />
                </div>
            </div>
        </div>
    );
};

export default AboutGalleryMobileRo;
