import React from "react";
import '../global.css'

const AboutGalleryMobile = () => {
  return (
    <div id="about" className="relative h-full min-h-full bg-gray-100 text-left text-lg text-white font-open-sans">
      <img
        className="absolute inset-0 w-full h-full object-cover"
        alt=""
        src="/bg4-1@2x.png"
      />
      <div className="relative flex flex-col justify-center items-center">
        <div className="text-center m-5 mb-0">
          <div className="text-3xl font-bold tracking-wide font-cormorant uppercase">
            Что такое DBD?
          </div>
          <p className="mt-4 font-light ">
            В этом захватывающем квесте вы окажетесь в жестоком мире Dead by Daylight, играя в роли выжившего.Необходимо отточить рефлексы, стратегически планировать каждый ход и помнить о главной миссии: починить 5 генераторов, которые станут ключом к вашему спасению. Погрузитесь в мир кошмаров и проверьте свои силы в самой смертельной охоте вашей жизни.
          </p>
        </div>
        <div className="w-[50%] mt-0">
          <img
            className="w-full"
            alt=""
            src="/icon.svg"
          />

        </div>
      </div>
    </div>
  );
};

export default AboutGalleryMobile;
