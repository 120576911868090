import React from 'react'
import '../global.css'

const FAQ = () => {
    return (
        <div id='times'>
            <div className="time-table-container relative mt-[150px] ml-[40px] mr-[40px]">
                <h1 className='sm:text-47xl text-[40px] text-white font-bellota'>Что нужно знать о нас?</h1>
                <table className="time-table">
                    <thead>
                        <tr>
                            <th>Время</th>
                            <th>Пн-Чт</th>
                            <th>Пт</th>
                            <th>Сб-Вс</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>13:00</td>
                            <td>900</td>
                            <td>900</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>14:30</td>
                            <td>900</td>
                            <td>900</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>16:00</td>
                            <td>950</td>
                            <td>1000</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>17:30</td>
                            <td>950</td>
                            <td>1000</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>19:00</td>
                            <td>1000</td>
                            <td>1000</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>20:30</td>
                            <td>1000</td>
                            <td>1000</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>22:00</td>
                            <td>1050</td>
                            <td>1050</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>23:30</td>
                            <td>1050</td>
                            <td>1050</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>00:45</td>
                            <td>1100</td>
                            <td>1100</td>
                            <td>1100</td>
                        </tr>
                        <tr>
                            <td>02:00</td>
                            <td>1100</td>
                            <td>1100</td>
                            <td>1100</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="extreme-mode-container relative">
                <h1 className="extreme-mode-title font-needleteeth-sp">Экстремальный режим</h1>
                <p className="extreme-mode-description ">
                    Только у нас у вас есть возможность выбрать <a className='no-underline text-rose-600' href='#footer'>ЭКСТРЕМАЛЬНЫЙ РЕЖИМ ИГРЫ !!!</a>
                    <br />ДОПОЛНИТЕЛЬНЫЕ 20 МИНУТ НА ПОИСК И РЕМОНТ ГЕНЕРАТОРОВ !!!
                    <br />Максимальный уровень страха, полный физический контакт с применением инвентаря, индивидуальные задания и разделения.
                </p>
            </div>
        </div>
    )
}

export default FAQ;
