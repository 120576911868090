import React from "react";
import '../global.css'

const AboutGalleryRo = () => {
    return (
        <div id="about" className="relative flex h-screen min-h-full bg-gray-100 text-left text-xl text-white font-open-sans gap-4">
            <img
                className="absolute inset-0 w-full h-full object-cover"
                alt=""
                src="/bg4-1@2x.png"
            />
            <div className="relative flex w-[75%] m-5 gap-y-[60px] flex-col justify-center">
                <div className="relative tracking-[2px] text-[58px] uppercase font-cormorant inline-block">
                    Ce este DBD?
                </div>
                <i className="relative content leading-[22px] inline-block font-light text-justify">
                    În această aventură captivantă, vă veți găsi într-o lume nemiloasă a Dead by Daylight, jucând rolul unui supraviețuitor. Trebuie să vă perfecționați reflexele, să planificați strategic fiecare mișcare și să vă amintiți misiunea principală: să reparați 5 generatoare care vor fi cheia salvării voastre. Cufundați-vă în lumea coșmarurilor și testați-vă forțele în cea mai mortală vânătoare din viața voastră.
                </i>
            </div>
            <div className="relative">
                <div className="flex items-center justify-center h-full">
                    <div className="w-[40%] min-w-[150px] ml-0 relative filter-[drop-shadow(0px_4px_150px_#000)_drop-shadow(0px_10px_150px_#000)_drop-shadow(0px_10px_50px_#000)]">
                        <img
                            className="relative w-full h-full"
                            alt=""
                            src="/icon.svg"
                        />
                        <img
                            className="relative w-full h-full"
                            alt=""
                            src="/text.svg"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGalleryRo;
