import React, { useState } from 'react';
import './Accordion.css'
const Accordion = ({ AccordionList }) => {
    const [openId, setId] = useState(null);
    const clickHandler = (id) => {
        if (id === openId) setId(null)
        else setId(id)
    }
    return (
        <ul className='accordion '>
            {AccordionList.map((Accitem, id) => {
                return (
                    <li key={id} className='accordion-item'>
                        <button className='accordion-header' onClick={() => clickHandler(id)}>{Accitem.q}</button>
                        <div className={`accordion-collapse ${id === openId ? "open" : ""}`}>
                            <div className='accordion-body '>{Accitem.a}</div>

                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default Accordion;
